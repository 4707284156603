import { faFacebook, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import css from './Footer.module.css';

const FooterSection = () => {
  return (
    <div className={css.topBar}>
      <div className={css.content}>
        <div>
          <p>
            Book <em>your</em> ideal party space.
          </p>
          <ul className={css.socialIcons}>
            <li>
              <a href="https://www.facebook.com/venue.io/" target="_blank">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/venue.io/" target="_blank">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@venue.io" target="_blank">
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/venue_io" target="_blank">
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
            </li>
          </ul>
        </div>
        <div></div>
        <div></div>
        {/* <nav>
          <ul>
            <li>
              <a href="">About</a>
            </li>
          </ul>
        </nav>
        <nav>
          <ul>
            <li>
              <a href="">Venues in Philadelphia</a>
            </li>
          </ul>
        </nav> */}
        <nav>
          <ul>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
            <li>
              <a href="/terms-of-service">Terms of Service</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className={css.bottomBar}>
        <p>&copy; {new Date().getFullYear()} Venue, LLC | All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default FooterSection;
