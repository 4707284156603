import {
  faCar,
  faHamburger,
  faMartiniGlass,
  faMusic,
  faPeopleRoof,
  faTree,
  faTv,
  faWheelchair,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';

export type AmenityAvailability = 'yes' | 'no';

export enum AmenityKey {
  OpenBar = 'openBarAvailable',
  Parking = 'parkingAvailable',
  Wifi = 'wifi',
  TVControl = 'tvControl',
  MusicControl = 'musicControl',
  Rooftop = 'rooftop',
  Outdoor = 'outdoor',
  DisabledAccess = 'disabledAccess',
  BringYourOwnFood = 'byof',
  BringYourOwnAlcohol = 'byob',
}

export const AmenityMapping = {
  [AmenityKey.BringYourOwnAlcohol]: {
    label: 'Bring your own alcohol',
    icon: faMartiniGlass,
  },
  [AmenityKey.BringYourOwnFood]: {
    label: 'Bring your own food',
    icon: faHamburger,
  },
  [AmenityKey.OpenBar]: {
    label: 'Open bar available',
    icon: faMartiniGlass,
  },
  [AmenityKey.Parking]: {
    label: 'Parking available',
    icon: faCar,
  },
  [AmenityKey.Wifi]: {
    label: 'Wifi available',
    icon: faWifi,
  },
  [AmenityKey.TVControl]: {
    label: 'TV control available',
    icon: faTv,
  },
  [AmenityKey.MusicControl]: {
    label: 'Music control available',
    icon: faMusic,
  },
  [AmenityKey.Rooftop]: {
    label: 'Rooftop seating',
    icon: faPeopleRoof,
  },
  [AmenityKey.Outdoor]: {
    label: 'Outdoor seating',
    icon: faTree,
  },
  [AmenityKey.DisabledAccess]: {
    label: 'Disabled access',
    icon: faWheelchair,
  },
};
