import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconEdit, IconSuccess, Button, InlineTextButton } from '..';
import css from './StripeConnectAccountStatusBox.module.css';
import Alert from 'venue/components/messaging/Alert/Alert';

const STATUS_VERIFICATION_NEEDED = 'verificationNeeded';
const STATUS_VERIFICATION_SUCCESS = 'verificationSuccess';
const STATUS_VERIFICATION_ERROR = 'verificationError';

const StripeConnectAccountStatusBox = (props) => {
  const { type, onGetStripeConnectAccountLink, inProgress, disabled } = props;

  if (type === STATUS_VERIFICATION_NEEDED) {
    return (
      <Alert type="warning">
        <h5>
          <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededTitle" />
        </h5>
        <p>
          <FormattedMessage id="StripeConnectAccountStatusBox.verificationNeededText" />
        </p>
        <Button
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </Button>
      </Alert>
    );
  } else if (type === STATUS_VERIFICATION_SUCCESS) {
    return (
      <Alert type="success">
        <h5>Everything looks good on our end!</h5>
        <p>Your Stripe account is up to date. Nothing to do here.</p>
        <Button
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.editAccountButton" />
        </Button>
      </Alert>
    );
  } else if (type === STATUS_VERIFICATION_ERROR) {
    return (
      <Alert type="error">
        <h5>
          <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedTitle" />
        </h5>
        <p>
          <FormattedMessage id="StripeConnectAccountStatusBox.verificationFailedText" />
        </p>
        <Button
          spinnerClassName={css.spinner}
          type="button"
          inProgress={inProgress}
          disabled={disabled}
          onClick={onGetStripeConnectAccountLink}
        >
          <FormattedMessage id="StripeConnectAccountStatusBox.getVerifiedButton" />
        </Button>
      </Alert>
    );
  } else {
    throw new Error(`Unknown type ${type} for StripeConnectAccountStatusBox`);
  }
};

export default StripeConnectAccountStatusBox;
