export enum GratuityType {
  Service = 'Service',
  Bar = 'Bar',
}

export interface Gratuity {
  percentage: number;
  type: GratuityType;
  applyToBase?: 'yes' | 'no';
}

export const gratuityLineItemCodes = Object.values(GratuityType).map(
  (type) => `line-item/${type}-gratuity`
);
