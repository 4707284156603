export enum Category {
  Bar = 'Bar',
  Boutique = 'Boutique',
  CoffeeShop = 'Coffee shop',
  OfficeSpace = 'Office space',
  Restaurant = 'Restaurant',
}

export enum SubCategory {
  BeerGarden = 'Beer garden',
  Brewery = 'Brewery',
  Casual = 'Casual',
  CocktailBar = 'Cocktail bar',
  Distillery = 'Distillery',
  Dive = 'Dive',
  FineDining = 'Fine dining',
  Karaoke = 'Karaoke',
  LBGTQ = 'LBGTQ',
  LiveMusic = 'Live music',
  NightClub = 'Night club',
  Sports = 'Sports',
  Steakhouse = 'Steakhouse',
  Upscale = 'Upscale',
  WineBar = 'Wine bar',
}

export enum GoodFor {
  BirthdayParties = 'Birthday parties',
  Breakfast = 'Breakfast',
  Brunch = 'Brunch',
  Dinner = 'Dinner',
  KidFriendly = 'Kid friendly',
  LargeGroups = 'Large groups',
  LateNight = 'Late night',
  OutdoorGatherings = 'Outdoor gatherings',
  QuietGatherings = 'Quiet gatherings',
  Seasonal = 'Seasonal',
}
