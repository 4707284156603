/**
 * MenuContent is a immediate child of Menu component sibling to MenuLabel.
 * Clicking MenuLabel toggles visibility of MenuContent.
 */
import classNames from 'classnames';
import React from 'react';

import css from './MenuContent.module.css';

interface MenuContentProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  contentRef?: React.Ref<HTMLDivElement>;
  isOpen?: boolean;
  rootClassName?: string;
  style?: React.CSSProperties;
}

const MenuContent = ({
  children,
  className,
  contentClassName,
  contentRef,
  isOpen,
  rootClassName,
  style,
}: MenuContentProps) => {
  const rootClass = rootClassName || css.root;
  const openClasses = isOpen ? css.isOpen : css.isClosed;
  const classes = classNames(rootClass, className, openClasses);
  const contentClasses = classNames(contentClassName || css.content);

  return (
    <div className={classes} ref={contentRef} style={style}>
      <ul className={contentClasses}>{children}</ul>
    </div>
  );
};

export default MenuContent;
