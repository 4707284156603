import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { MouseEvent } from 'react';

import css from './Button.module.css';

interface ButtonProps {
  classes?: string;
  children: React.ReactNode;
  icon?: IconDefinition;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  size?: 'small' | 'default' | 'large';
  style?: 'primary' | 'secondary' | 'tertiary' | 'destructive';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const Button = ({
  children,
  classes,
  onClick,
  icon,
  disabled,
  style = 'primary',
  size = 'default',
  type = 'button',
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        css.root,
        classes,
        [css[`Button--style-${style}`]],
        [css[`Button--size-${size}`]]
      )}
      onClick={onClick}
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : null}
      {children}
    </button>
  );
};

export default Button;
