import {
  IconDefinition,
  faCheckCircle,
  faExclamation,
  faExclamationTriangle,
  faInfoCircle,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React from 'react';

import css from './Alert.module.css';

interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info';
  children: React.ReactNode;
}

const Alert = ({ type, children }: AlertProps) => {
  let icon: IconDefinition = faQuestion;
  switch (type) {
    case 'warning':
      icon = faExclamationTriangle;
      break;
    case 'error':
      icon = faExclamation;
      break;
    case 'info':
      icon = faInfoCircle;
      break;
    case 'success':
      icon = faCheckCircle;
      break;
    default:
      icon = faQuestion;
      break;
  }
  return (
    <div className={clsx(css.container, [css[`type--${type}`]])}>
      <FontAwesomeIcon icon={icon} size="2x" />
      <div>{children}</div>
    </div>
  );
};

export default Alert;
