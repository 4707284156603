import React from 'react';

import { taxLineItemCodes } from 'venue/models/Tax.model';
import { LineItem } from 'venue/models/Transaction.model';

import css from './OrderBreakdown.module.css';
import { formatMoney } from '../../util/currency';
import { LINE_ITEMS } from '../../util/types';

interface LineItemTaxesMaybeProps {
  lineItems: LineItem[];
  isProvider: boolean;
  intl: object;
}

const LineItemTaxesMaybe = ({ lineItems, isProvider, intl }: LineItemTaxesMaybeProps) => {
  const allItems = lineItems.filter(
    (item) => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );

  const items = isProvider
    ? allItems.filter((item) => item.includeFor?.includes('provider'))
    : allItems.filter((item) => item.includeFor?.includes('customer'));

  const taxLineItems = items.filter((item) => taxLineItemCodes.includes(item.code));

  return taxLineItems.length > 0
    ? taxLineItems.map((item, i) => {
        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItemGroup}>
            <div className={css.lineItem}>
              <span className={css.itemLabel}>{item.name}</span>
              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          </div>
        );
      })
    : null;
};

export default LineItemTaxesMaybe;
