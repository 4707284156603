import Decimal from 'decimal.js';
import React from 'react';

import { gratuityLineItemCodes } from 'venue/models/Gratuity.model';
import { taxLineItemCodes } from 'venue/models/Tax.model';
import { LineItem } from 'venue/models/Transaction.model';

import css from './OrderBreakdown.module.css';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';

const { Money } = sdkTypes;

interface LineItemSubTotalMaybeProps {
  lineItems: LineItem[];
  userRole: string;
  intl: object;
  code: string;
  marketplaceCurrency: object;
  exclusionary?: boolean;
}

/**
 * Calculates the total price in sub units for multiple line items.
 */
const lineItemsTotal = (lineItems: LineItem[], marketplaceCurrency: object) => {
  const amount = lineItems.reduce((total, item) => {
    return total.plus(item.lineTotal.amount);
  }, new Decimal(0));
  const currency = lineItems[0] ? lineItems[0].lineTotal?.currency : marketplaceCurrency;
  return new Money(amount, currency ?? marketplaceCurrency);
};

/**
 * Checks if line item represents commission
 */
const isCommission = (lineItem: LineItem) => {
  return (
    lineItem.code === LINE_ITEM_PROVIDER_COMMISSION ||
    lineItem.code === LINE_ITEM_CUSTOMER_COMMISSION
  );
};

/**
 * Returns exclusion of line items
 */
const exclusionaryLineItems = (lineItems: LineItem[], exclusionairy?: boolean) => {
  const _lineItems = lineItems.filter((item) => !isCommission(item) && !item.reversal);

  if (exclusionairy) {
    return _lineItems.filter(
      (item) =>
        !taxLineItemCodes.includes(item.code) &&
        !gratuityLineItemCodes.includes(item.code) &&
        item.code !== 'line-item/premium-day-charge'
    );
  }

  return _lineItems;
};

/**
 * Check if there is a commission line-item for the given user role.
 */
const hasCommission = (lineItems: LineItem[], userRole: string) => {
  let commissionLineItem = null;

  if (userRole === 'customer') {
    commissionLineItem = lineItems.find((item) => item.code === LINE_ITEM_CUSTOMER_COMMISSION);
  } else if (userRole === 'provider') {
    commissionLineItem = lineItems.find((item) => item.code === LINE_ITEM_PROVIDER_COMMISSION);
  }
  return !!commissionLineItem;
};

const LineItemSubTotalMaybe = ({
  lineItems,
  code,
  userRole,
  intl,
  marketplaceCurrency,
  exclusionary,
}: LineItemSubTotalMaybeProps) => {
  const refund = lineItems.find((item) => item.code === code && item.reversal);

  // Show subtotal only if commission line-item is applicable to user or refund is issued.
  const showSubTotal = hasCommission(lineItems, userRole) || refund;

  // all non-reversal, non-commission line items
  const subTotalLineItems = exclusionaryLineItems(lineItems, exclusionary);
  // line totals of those line items combined
  const subTotal = lineItemsTotal(subTotalLineItems, marketplaceCurrency);

  const formattedSubTotal = subTotalLineItems.length > 0 ? formatMoney(intl, subTotal) : null;

  return formattedSubTotal && (showSubTotal || exclusionary) ? (
    <>
      <div className={css.subTotalLineItem}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.subTotal" />
        </span>
        <span className={css.itemValue}>{formattedSubTotal}</span>
      </div>
    </>
  ) : null;
};

export default LineItemSubTotalMaybe;
