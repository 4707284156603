/**
 * MenuItem is part of Menu and specifically a child of MenuContent.
 * MenuItems should have a 'key' prop specified.
 * https://facebook.github.io/react/docs/lists-and-keys.html#keys
 *
 * Example:
 *   <MenuItem key="item 1"><a href="example.com">Click me</a><MenuItem>
 */
import clsx from 'clsx';
import React from 'react';

import css from './MenuItem.module.css';

interface MenuItemProps {
  children: React.ReactNode;
  className?: string;
  rootClassName?: string;
  isActive?: boolean;
}

const MenuItem = ({ children, className, isActive, rootClassName }: MenuItemProps) => {
  const rootClass = rootClassName || css.root;
  const classes = clsx(rootClass, className);

  return (
    <li className={clsx(classes, { [css.isActive]: isActive })} role="menuitem">
      {children}
    </li>
  );
};

export default MenuItem;
