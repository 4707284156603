export enum TaxLiabilityType {
  Sales = 'Sales',
  Liquor = 'Liquor',
  Food = 'Food',
  City = 'City',
  County = 'County',
}

export interface TaxLiability {
  type: TaxLiabilityType;
  percentage: number;
}

export const taxLineItemCodes = Object.values(TaxLiabilityType).map(
  (type) => `line-item/${type}-tax`
);
