import React from 'react';

import { LineItem } from 'venue/models/Transaction.model';

import css from './OrderBreakdown.module.css';
import { formatMoney } from '../../util/currency';
import { LINE_ITEMS } from '../../util/types';

interface LineItemAddOnsMaybeProps {
  lineItems: LineItem[];
  isProvider: boolean;
  intl: object;
}

const LineItemAddOnsMaybe = ({ lineItems, isProvider, intl }: LineItemAddOnsMaybeProps) => {
  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(
    (item) => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );

  const items = isProvider
    ? allItems.filter((item) => item.includeFor?.includes('provider'))
    : allItems.filter((item) => item.includeFor?.includes('customer'));

  const addonLineItems = items.filter((item) => item.code.includes('line-item/addon'));

  return addonLineItems.length > 0 ? (
    <>
      {addonLineItems.map((item, i) => {
        const quantity = item.quantity;

        const unit =
          item.unitType === 'perHour'
            ? parseInt(quantity) > 1
              ? 'hours'
              : 'hour'
            : item.unitType === 'perPersonPerHour'
              ? item.numberOfPersons > 1
                ? 'people per hour'
                : 'person per hour'
              : item.unitType === 'perPerson'
                ? item.numberOfPersons > 1
                  ? 'people'
                  : 'person'
                : item.unitType;

        const label =
          quantity && parseInt(quantity) > 0 && item.unitType !== 'fixed'
            ? `${item.name} x ${
                item.unitType === 'perPerson' || item.unitType === 'perPersonPerHour'
                  ? item.numberOfPersons
                  : quantity
              } ${unit}`
            : item.name;

        const originalTotal = item.originalTotal
          ? formatMoney(intl, item.originalTotal)
          : undefined;

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItemGroup}>
            <div className={css.lineItem}>
              <span className={css.itemLabel}>
                <span>{label}</span>
                {originalTotal ? (
                  <span className={css.itemOriginalTotal}>{originalTotal} value</span>
                ) : null}
              </span>
              <span className={css.itemValue}>{formattedTotal}</span>
            </div>
          </div>
        );
      })}
    </>
  ) : null;
};

export default LineItemAddOnsMaybe;
