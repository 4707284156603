import clsx from 'clsx';
import React from 'react';
import { formatMoney } from '../../util/currency';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { LINE_ITEM_DAY, LINE_ITEM_HOUR, LINE_ITEM_NIGHT, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = (props) => {
  const { lineItems, code, intl } = props;
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
      ? 'OrderBreakdown.baseUnitDay'
      : isHourly
        ? 'OrderBreakdown.baseUnitHour'
        : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find((item) => item.code === code && !item.reversal);

  const quantity = unitPurchase?.quantity ? unitPurchase.quantity.toString() : null;

  const originalTotal = unitPurchase?.originalTotal
    ? formatMoney(intl, unitPurchase.originalTotal)
    : null;

  const unitPrice = unitPurchase?.unitPrice ? formatMoney(intl, unitPurchase.unitPrice) : null;

  const total = unitPurchase?.lineTotal ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const minimumPriceMet = unitPurchase.minimumPriceMet;

  return quantity && total ? (
    <div className={clsx(css.lineItemGroup, { [css.minimumMet]: minimumPriceMet })}>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          {minimumPriceMet ? (
            <FormattedMessage
              id={'OrderBreakdown.minimumMet'}
              values={{ unitPrice: originalTotal, quantity }}
            />
          ) : (
            <FormattedMessage id={translationKey} values={{ unitPrice: unitPrice, quantity }} />
          )}
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
      {minimumPriceMet ? <span className={css.minimumMetMessage}>Minimum met!</span> : null}
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
